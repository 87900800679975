<template>
  <div class="page-containner">
    <div class="page-content">
      <template v-if="$store.getters.getOnlyRead">
        <!-- 只读时只能看到 "app-文章" 和 "网络文章" -->
        <div v-if="showEngArticleList" class="tabs-wrapper" style="margin :1em 0 1.25em">
          <router-link class="tab" active-class="active" to="/HomeListPage/article">App-文章</router-link>
          <router-link
            v-if="showEngArticleList"
            class="tab"
            active-class="active"
            to="/HomeListPage/engArticleList"
          >网络文章</router-link>
        </div>
      </template>
      <template v-else>
        <div
          v-if="showGameEntry || showTeachmaterialEntry || showEngArticleList||showLongSentence||showCourseware"
          class="tabs-wrapper"
          style="margin :1em 0 1.25em"
        >
          <router-link class="tab" active-class="active" to="/HomeListPage/article">App-文章</router-link>
          <router-link
            v-if="showGameEntry"
            class="tab"
            active-class="active"
            to="/HomeListPage/game"
          >App-游戏</router-link>
          <router-link
            v-if="showCourseware"
            class="tab"
            active-class="active"
            to="/HomeListPage/courseware"
          >App-课件</router-link>
          <router-link
            v-if="showTeachmaterialEntry"
            class="tab"
            active-class="active"
            to="/HomeListPage/teaching"
          >App-教材</router-link>
          <router-link
            v-if="showEngArticleList"
            class="tab"
            active-class="active"
            to="/HomeListPage/engArticleList"
          >网络文章</router-link>
          <router-link
            v-if="showLongSentence"
            class="tab"
            active-class="active"
            to="/HomeListPage/longSentence"
          >长难句</router-link>
          <router-link
            v-if="showChangeLevel"
            class="tab"
            active-class="active"
            to="/HomeListPage/changeLevel"
          >文章调级</router-link>
        </div>
      </template>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlQuery: {}
    };
  },
  components: {},
  computed: {
    configs() {
      return this.$store.getters.getAllLauns;
    },
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    laun() {
      return this.$store.getters.getLaun;
    },

    // ----------------菜单入口逻辑控制-------------------

    showChangeLevel() {
      // 文章调级
      return /ChangeLevel/i.test(this.$store.getters.getRoles);
    },

    showGameEntry() {
      // 中文的 0编辑,5配音,6六审 才能看到游戏界面！
      return this.laun === "cn" && [0, 5, 6].includes(this.roleCode);
    },
    showTeachmaterialEntry() {
      // 教材 [英文 改为 中英文都可以]
      // 0编辑,5配音,6六审 7翻译 才能看到教材界面！
      // 新增：4配图也可以看到教材，并对已发布的重写配图。
      return [0, 4, 5, 6, 7].includes(this.roleCode);
    },
    showEngArticleList() {
      // 6六审 才能看到“网络文章”界面！
      return (
        this.laun === "en" &&
        ([6].includes(this.roleCode) || this.$store.getters.getOnlyRead)
      );
    },
    showLongSentence() {
      return this.laun === "en" && [0, 6].includes(this.roleCode);
    },
    showCourseware() {
      // 课件
      return [0, 6].includes(this.roleCode);
    }
  },
  mounted() {},
  watch: {
    laun() {
      if (!this.laun) {
        return;
      }
      this.$router.replace({
        path: "/HomeListPage"
      });
    }
  }
};
</script>

<style scoped>
.page-containner {
  background: rgba(242, 242, 242, 1);
}
.page-containner .page-content {
  width: 1000px;
  margin: 0 auto 0;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  min-height: 86vh;
}
</style>
